import {DoppeActionLayout} from './doppe-action-layout';
import {DoppeActionListItemClickBehaviour} from './doppe-action-list-item-click-behaviour';
import {DoppeDtoAction, doppeDtoActionGetDefaultSearchListSettingsProps} from './doppe-dto-action';
import {WixImageResource, WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue
} from './doppe-hideable-value';
import {
    BymoPageActionMediaLayout,
    BymoPageActionMediaSize,
    BymoPageActionMediaSizing,
    BymoPageActionMediaViewLayout,
    BymoPageActionMediaViewSettings
} from '../bymo-pages';
import {
    HtmlObjectHorizontalPosition,
    HtmlObjectVerticalPosition,
    OmitStrict,
    PartiallyRequired,
    PickByValueExact
} from '@wix/devzai-utils-common';
import {DoppeActionCtaButtonSettings, getDefaultDoppeActionCtaButtonSettings} from './doppe-action-cta-button-settings';

export interface DoppeListActionSettings<ItemSettings extends DoppeListActionSettings.ListItemBaseSettings = any> {
    listDataItems: ItemSettings[];
    itemShowDescription: boolean;
    itemShowImage: boolean;
    itemShowTitle: boolean;
    itemShowTextOnImage: boolean;
    layout: DoppeActionLayout;
    latestNonPremiumLayout?: DoppeActionLayout;
    listSearchSettings: DoppeDtoAction.ListSearchSettings;
}


export namespace DoppeListActionSettings {

    export interface ListItemBaseSettings {
        id: string;
        enabled: boolean;
        deleted: boolean;
        displayInSearch: boolean;
    }

    export interface DeprecatedFields {
        showButton: boolean;
        buttonText: string;
        buttonUrl: string;
    }

    export type ResolveListItemSettings<
        T extends DoppeListActionSettings,
        K extends keyof T & string
    > = T[K] extends Array<infer U> ? U : never;

    export type ResolvePartialAndDeprecatedActionSettings<
        T extends DoppeListActionSettings,
        V extends {id: string},
        K extends keyof PickByValueExact<T, V[]> & string
    > = Partial<OmitStrict<T, K>> & {
        [key in K]?: PartiallyRequired<Partial<V>, 'id'>[]
    } & DoppeListActionSettings.DeprecatedFields;

    export interface WithExternalDataSourceSupport<T extends {}> {
        externalDataSource: null | T;
        externalDataSourceItemsCount: number;
    }

    export interface WithCtaButton  extends DoppeActionCtaButtonSettings {

    }

    export interface WithMainDetails  {
        mainImage: DoppeHideableValue<WixMediaResource | null>;
        mainMediaViewSettings: BymoPageActionMediaViewSettings;
        mainMediaViewLayout: BymoPageActionMediaViewLayout;
        mainTitle: DoppeHideableValue<string>;
    }

    export interface WithItemClickBehaviour  {
        itemClickBehaviour: DoppeActionListItemClickBehaviour;
    }
}

export function doppeListActionWithMainDetailsGetMainImage(settings: DoppeListActionSettings.WithMainDetails, listItemImage: WixMediaResource | null) {
    return doppeHideableValueIsVisibleAndNotEqualValue(settings.mainImage, null) ? settings.mainImage : listItemImage;

}

export function doppeListActionWithMainDetailsReplaceMainImage(actionSettings: DoppeListActionSettings.WithMainDetails, externalImagesUrlToImageResourceMap: Map<string, WixImageResource>) {
    const mainImage = doppeHideableValueGetValue(actionSettings.mainImage);
    if (mainImage) {
        actionSettings.mainImage = doppeHideableValueModifyValue(actionSettings.mainImage, () => {
            return wixMediaResourceReplaceExternalImage(mainImage, externalImagesUrlToImageResourceMap)
        })
    }
}

export function doppeListActionGetDefaultListSettings(overrides?: Partial<DoppeListActionSettings>) {

    const {
        ...restOverrides
    } = overrides ?? {}

    return {
        listDataItems: [],
        layout: DoppeActionLayout.Column,
        itemShowDescription: true,
        itemShowTitle: true,
        itemShowImage: true,
        itemShowTextOnImage: false,
        listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),

        ...getDefaultDoppeActionCtaButtonSettings({
            buttonText: '',
            url: 'https://www.website.com',
            showButton: false,
        }),
        ...restOverrides
    } satisfies DoppeListActionSettings;
}

export function doppeListActionListItemGetDefaultListItemSettings(id: string, overrides?: Partial<DoppeListActionSettings.ListItemBaseSettings>) {
    return {
        id: id,
        enabled: true,
        deleted: false,
        displayInSearch: true,
        ...overrides
    } satisfies DoppeListActionSettings.ListItemBaseSettings;
}

export function doppeListActionGetItemClickBehaviourDefaultListSettings(overrides?: Partial<DoppeListActionSettings.WithItemClickBehaviour>) {
    return {
        itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
        ...overrides
    } satisfies DoppeListActionSettings.WithItemClickBehaviour;
}

export function doppeListActionGetWithExternalDataSourceDefaultListSettings(overrides?: Partial<DoppeListActionSettings.WithExternalDataSourceSupport<any>>) {
    return {
        externalDataSource: null,
        externalDataSourceItemsCount: 4,
        ...overrides
    } satisfies DoppeListActionSettings.WithExternalDataSourceSupport<any>;
}

export function doppeListActionGetMainDetailsDefaultSettings (
    options: {
        defaultTitle?: string;
    } = {},
    overrides?: Partial<DoppeListActionSettings.WithMainDetails>,
) {
    return {
        mainImage: doppeHideableValueCreateHidden(null),
        mainTitle: doppeHideableValueCreateHidden(options.defaultTitle ?? 'Title'),
        mainMediaViewSettings: {
            mediaLayout: BymoPageActionMediaLayout.Cropped,
            mediaSizing: BymoPageActionMediaSizing.Custom,
            size: BymoPageActionMediaSize.Ratio3By2,
            alignment: {
                horizontal: HtmlObjectHorizontalPosition.Center,
                vertical: HtmlObjectVerticalPosition.Center
            }
        },
        mainMediaViewLayout: BymoPageActionMediaViewLayout.FullWidth,
        ...overrides
    } satisfies DoppeListActionSettings.WithMainDetails;
}

export function convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(
    actionSettings: Partial<DoppeListActionSettings & DoppeListActionSettings.WithCtaButton & DoppeListActionSettings.DeprecatedFields>
) {
    return getDefaultDoppeActionCtaButtonSettings({
        buttonText: actionSettings.buttonText,
        url: actionSettings.buttonUrl ?? '',
        showButton: actionSettings.showButton !== undefined ? actionSettings.showButton : false,
    });
}


export function doppeListActionSettingsFilterHiddenListItemsFromActionView<T extends DoppeListActionSettings.ListItemBaseSettings>(items?: T[]) {
    return items?.filter(doppeListActionSettingsIsListItemVisibleInAction) ?? [];
}

export function doppeListActionSettingsFilterHiddenListItemsFromSearchResults<T extends DoppeListActionSettings.ListItemBaseSettings>(items?: T[]) {
    return items?.filter(doppeListActionSettingsIsListItemVisibleInSearchResults) ?? [];
}

export function doppeListActionSettingsIsListItemVisibleInAction<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return item.enabled && !item.deleted;
}

export function doppeListActionSettingsIsListItemHiddenInActionAndNotDeleted<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return !item.enabled && !item.deleted;
}

export function doppeListActionSettingsIsListItemVisibleInSearchResults<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return doppeListActionSettingsIsListItemVisibleInAction(item) && item.displayInSearch;
}

export function doppeListActionSettingsIsListItemIsDeleted<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return item.deleted;
}

export function doppeListActionSettingsIsListItemIsVisible<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return item.enabled;
}