import {
    DoppeActionCTAButton,
    DoppeActionCTAType,
    DoppeActionCTATypeLinkAutoSource,
    DoppeActionSettingsCTAButtonSettings
} from './doppe-action-cta-button';
import {autoValueCreate} from '@wix/devzai-utils-common';
import {DoppeHideableValue} from './doppe-hideable-value';

export interface DoppeActionCtaButtonSettings {
    ctaButton: DoppeActionCTAButton;
}

export function getDefaultDoppeActionCtaButtonSettings(options: {
    buttonText?: DoppeHideableValue<string>,
    url?: string,
    showButton: boolean
    settings?: DoppeActionSettingsCTAButtonSettings
}): DoppeActionCtaButtonSettings {

    const {
        buttonText,
        url,
        showButton,
        settings
    } = options;


    return {
        ctaButton: {
            settings: settings ?? {
                type: DoppeActionCTAType.Link,
                url: url !== undefined ? url : autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL),
            },
            text: buttonText ? buttonText : '',
            showButton: showButton,
        }
    }
}


