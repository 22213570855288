import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {doppeActionTypeTelegramButton} from './doppe-action-type-telegram-button';
import {BymoPageLayout} from '../../components/bymo-page-style/bymo-page-style';
import {DoppeViewerModalPanelLayout} from '../../components/doppe-viewer-modal-panel/doppe-viewer-modal-panel';
import {
    DoppeViewerModalOpeningAnimation
} from '../../components/doppe-viewer-modals-controller/doppe-viewer-modals-controller';
import {
    DoppeViewerContactMethodActionsPanel
} from '../../components/doppe-viewer-contact-method-action-panel/doppe-viewer-contact-method-action-panel';
import React from 'react';
import {asyncExecuteInBackground, uuidCreate} from '@wix/devzai-utils-common';
import {downloadContactCard} from '../../client-server-common/types/doppe-dto-contact-details';
import {
    DoppeDtoContactMethod,
    doppeDtoContactMethodLinkResolveValue,
    DoppeDtoContactMethods
} from '../../client-server-common/types/doppe-dto-contact-method';
import {doppeListActionListItemGetDefaultListItemSettings} from '../../client-server-common';

export const doppeActionTypeTelegramButtonViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeTelegramButton, {

    getActionButtonProps: actionContext => {

        const action = actionContext.action;

        const value = action.settings.url;
        const type = DoppeDtoContactMethods.Telegram;

        const showContactPanel = action.settings.showContactPanel;

        if (showContactPanel) {
            return {
                onClick:  () => {
                    actionContext.modalsController.showInnerModal({
                        openingAnimation: bymoPageLayout => {
                            const modalPanelLayout = bymoPageLayout === BymoPageLayout.Wide ?
                                DoppeViewerModalPanelLayout.Inner :
                                DoppeViewerModalPanelLayout.Drawer;

                            switch (modalPanelLayout) {
                                case DoppeViewerModalPanelLayout.Drawer: {
                                    return DoppeViewerModalOpeningAnimation.SlideInFromBottom;
                                }
                                default: {
                                    return DoppeViewerModalOpeningAnimation.FadeIn;
                                }
                            }
                        },
                        content: <DoppeViewerContactMethodActionsPanel
                            title={action.name}
                            type={type}
                            displayValue={value}
                            showNotification={(text: string) => {
                                actionContext.showNotification({
                                    message: text
                                })
                            }}
                            onClick={() => {
                            }}
                            saveAsContact={()=>{
                                asyncExecuteInBackground(async () => {
                                    await downloadContactCard({
                                        media: null,
                                        name: null,
                                        company: '',
                                        notes: '',
                                        jobTitle: '',
                                        contactMethods: [{
                                            type: type,
                                            displayValue: value,
                                            title: '',
                                            media: null,
                                            ...doppeListActionListItemGetDefaultListItemSettings(uuidCreate()),
                                        } as DoppeDtoContactMethod],
                                    })
                                }, {});
                            }}
                        />
                    });

                },
            }
        }
        else {
            return  {
                href: doppeDtoContactMethodLinkResolveValue({
                    type: DoppeDtoContactMethods.Telegram,
                    displayValue: action.settings.url
                }),
                target: '_blank',
                rel: 'noopener',
            }
        }

    }
})