import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, arrayRemoveNullValues, immerProduce, PartiallyOptional} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionLayout,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisible,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionListItemGetDefaultListItemSettings,
    DoppeListActionSettings,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {DoppeActionTypeContactCard} from '../doppe-action-type-contact-card/doppe-action-type-contact-card';
import {DoppeActionFullName, doppeActionFullNameGetName} from '../../client-server-common/types/doppe-action-full-name';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL,
    DoppeActionCTAType
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeDtoContactMethod} from '../../client-server-common/types/doppe-dto-contact-method';


export namespace DoppeActionTypeContactCardList {

    export interface ContactCardSettings extends DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeContactCard.ActionSettings {
        name: DoppeActionFullName;
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings<ContactCardSettings>,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour {
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewSaveButtonText: string;
    }

    export interface DeprecatedFields {
        contacts?: ContactCardSettings[];
    }
}


export const doppeActionTypeContactCardList = doppeActionTypeDefineMetadata<DoppeActionTypeContactCardList.ActionSettings>({
    id: 'io.bymo.action/contact-card-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ...restSettings
        } = action.settings;

        return {
            previewShowDescription: true,
            previewShowTitle: true,
            previewSaveButtonText: '',
            ...doppeListActionGetDefaultListSettings({
                itemShowTextOnImage: true,
                layout: DoppeActionLayout.Grid,
                listDataItems: listDataItems.map(contact => {

                    const {
                        buttonText,
                        notes,
                        saveContactProfileImage,
                        contactMethods
                    } = contact;

                    return {
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: doppeHideableValueGetVisibleValue(buttonText, ''),
                            showButton: doppeHideableValueIsVisible(buttonText),
                            settings: {
                                type: DoppeActionCTAType.SaveContact,
                                notes: notes,
                                saveContactProfileImage: saveContactProfileImage
                            }
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettings(contact.id),
                        contactMethods: (contactMethods as DoppeActionTypeContactCard.xxx[]).map(method => {

                            const {
                                hidden
                            } = method

                            return {
                                ...doppeListActionListItemGetDefaultListItemSettings(method.id, {
                                    enabled: !hidden
                                }),
                                ...method as PartiallyOptional<DoppeDtoContactMethod, 'enabled'>,
                            }
                        }) as DoppeDtoContactMethod[],
                        ...contact as PartiallyOptional<DoppeActionTypeContactCardList.ContactCardSettings, 'ctaButton' | 'contactMethods'>,
                    };
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...restSettings
        }
    },
    canRenderInPage: true,
    isWidgetOpener: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: false,
    supportsListLayout: true,
    resolveListItemsCount: actionSettings => {
        return actionSettings.listDataItems.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: doppeActionFullNameGetName(item.name),
                action: action,
                description: item.description,
                image: item.media?.[0] ?? null,
                itemId: item.id,
                url: null,
                couponCode: doppeHideableValueCreateHidden(''),
                ribbon: doppeHideableValueCreateHidden(''),
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.media ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.media?.[0] ?? null);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.listDataItems.forEach(contact => {
                doppeActionCtaButtonResetSettingsForLock(contact);
            });

            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const contact of actionSettings.listDataItems) {
                if (contact.media) {
                    contact.media = contact.media.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});

