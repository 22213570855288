import {
    arrayRemoveNullValues,
    AutoValue,
    autoValueCreate,
    autoValueIsAutoValue,
    Values
} from '@wix/devzai-utils-common';
import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisible
} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeDtoVideo} from '../../client-server-common/types/doppe-dto-video';
import {
    DoppeBymoPageVideoSource,
    doppeBymoPageVideoSourceResolveVideoUrl,
    DoppeExternalVideoProvider,
    doppeExternalVideoProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';

export const DoppeActionTypeVideoURLAutoSource = {
    SameAsVideoURL: 'SameAsVideoURL',
} as const;

export type DoppeActionTypeVideoURLAutoSource = Values<typeof DoppeActionTypeVideoURLAutoSource>;

export namespace DoppeActionTypeVideo {

    export type DoppeActionTypeVideoURLSource = AutoValue<DoppeActionTypeVideoURLAutoSource> | string;

    export interface ActionSettings extends DoppeActionCtaButtonSettings {
        externalVideoUrl: DoppeBymoPageVideoSource;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        videoButtonText: DoppeHideableValue<string>;
        videoPlayerSettings: {
            autoplay: boolean;
            muteVideo: boolean;
        },
        videoImage: WixMediaResource | null;
        videoProvider: DoppeExternalVideoProvider | null,
        videoProviderId: string | null,
        videoButtonURL: DoppeActionTypeVideoURLSource;
    }
}

export const doppeActionTypeVideo = doppeActionTypeDefineMetadata<DoppeActionTypeVideo.ActionSettings>({
    id: 'io.bymo.action/video',
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            if (!autoValueIsAutoValue(actionSettings.videoButtonURL)) {
                actionSettings.videoButtonURL = '';
                doppeActionCtaButtonResetSettingsForLock(actionSettings);
            }
        }, options);
    },
    resolveActionSettings: action => {

        const {
            videoButtonText,
            videoButtonURL,
            ...restSettings
        } = action.settings;

        const defaultSettings = doppeActionTypeVideoGetDefaultSettings();
        const buttonText = videoButtonText ?? defaultSettings.videoButtonText;

        return {
            ...defaultSettings,
            videoButtonText: buttonText,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: doppeHideableValueGetValue(buttonText),
                showButton: doppeHideableValueIsVisible(buttonText),
                url: videoButtonURL ? (autoValueIsAutoValue(videoButtonURL) ? undefined : videoButtonURL) : undefined
            }),
            ...restSettings
        }
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.videoProvider !== null && actionSettings.videoProviderId !== null ?
                doppeExternalVideoProviderResolveBusinessEntityReference(
                    actionSettings.videoProvider,
                    actionSettings.videoProviderId
                ) :
                null
        ])
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings, doppeBymoPageVideoSourceResolveVideoUrl(actionSettings.externalVideoUrl)) ?? null;
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
})

export function doppeActionTypeVideoResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeVideo.ActionSettings,
    _doppeDtoCoupon: DoppeDtoVideo
): DoppeActionTypeVideo.ActionSettings {
    return actionSettings
}

export function doppeActionTypeVideoGetDefaultSettings(): DoppeActionTypeVideo.ActionSettings {
    return {
        externalVideoUrl: '',
        videoPlayerSettings: {
            autoplay: true,
            muteVideo: false
        },
        title: doppeHideableValueCreateHidden('Video Title'),
        description: doppeHideableValueCreateHidden('Video Description'),
        videoButtonText: doppeHideableValueCreateHidden('Watch on YouTube'),
        videoImage: null,
        videoProvider: null,
        videoProviderId: null,
        videoButtonURL: autoValueCreate(DoppeActionTypeVideoURLAutoSource.SameAsVideoURL),
        ...getDefaultDoppeActionCtaButtonSettings({
            buttonText: 'Watch on YouTube',
            showButton: false,
        }),
    }
}