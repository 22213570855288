import {
    DoppeDtoAction,
    doppeDtoActionExtendWithDefaultProps,
    doppeDtoActionGetDefaultLockSettingsProps,
    doppeDtoActionGetDefaultSearchSettingsProps
} from '../types/doppe-dto-action';
import React from 'react';
import {DoppeActionComponentActionButtonProps, IDoppeViewerActionContext} from '../../doppe-sdk';
import {
    enumReduce,
    Evaluable,
    objectMapValues,
    ObservableValue,
    OmitStrict,
    PartiallyRequired,
    Values
} from '@wix/devzai-utils-common';
import {v4 as uuidv4} from 'uuid';
import {WixImageResource, WixMediaResource} from '@wix/devzai-common-wix';
import {
    DoppeAutomationTriggers,
    DoppeBusinessEntityReference,
    DoppeDtoCouponCodeData,
    doppeHideableValueCreateHidden,
} from '../types';
import {DoppeActionWidgetPopupMode} from '../types/doppe-action-widget-popup-mode';
import {DoppePremiumFeaturesStatus} from '../doppe-premium';
import ListSearchSettings = DoppeDtoAction.ListSearchSettings;

export const DoppeActionTypeRenderingInPageSupport = {
    FullyEnabled: true,
    Disabled: false,
    AsCardOnly: 'AsCardOnly'
} as const;

export type DoppeActionTypeRenderingInPageSupport = Values<typeof DoppeActionTypeRenderingInPageSupport>;

export namespace DoppeActionType {
    export type InferProps<ACTION_TYPE extends DoppeActionTypeMetadata<any>> =
        ACTION_TYPE extends DoppeActionTypeMetadata<infer PROPS> ? PROPS : never;

    export type InstantiationData<ACTION_TYPE extends DoppeActionType<any>> = {
        settings?: Partial<DoppeActionType.InferProps<ACTION_TYPE>>
        lockSettings?: Partial<DoppeDtoAction['lockSettings']>;
        searchSettings?: Partial<DoppeDtoAction['searchSettings']>;
    } & OmitStrict<
        PartiallyRequired<Partial<DoppeDtoAction<DoppeActionType.InferProps<ACTION_TYPE>>>, 'name'>,
        'settings' | 'isDummyAction' | 'actionTypeId' | 'lockSettings' | 'searchSettings'
    >

    export interface WidgetProps<PROPS extends {}> extends React.HTMLAttributes<any> {
        actionContext: IDoppeViewerActionContext<PROPS>;
    }
}

export type DoppeActionTypeViewerMetadata<PROPS extends {}, VIEWER_COMP = any> = {
    /**
     * An optional function that provides an in-page view for an instance of this action type, that's an
     * alternative for the standard action button.
     */
    renderWidget?: (renderProps: DoppeActionType.WidgetProps<PROPS>, viewerComponents: VIEWER_COMP) => React.ReactElement;

    /**
     * An action type can provide this function that returns the properties that should be used to render
     * the action component for it, when it's rendered as an action button.
     */
    getActionButtonProps?: (actionContext: IDoppeViewerActionContext<PROPS>, observableActionContext: ObservableValue<IDoppeViewerActionContext<PROPS>>) => DoppeActionComponentActionButtonProps;

    activateCTA?: (actionContext: IDoppeViewerActionContext<PROPS>, observableActionContext: ObservableValue<IDoppeViewerActionContext<PROPS>>) => void;

    loadViewerComponents?: () => Promise<VIEWER_COMP>;

    widgetPopupMode?: DoppeActionWidgetPopupMode;
}



export type DoppeActionTypeMetadata<PROPS extends {}, PARTIAL_PROPS extends {} = any> = {
    id: DoppeActionType.Id;

    /**
     * Even though the settings of an action of a specific type are stored on it, it's possible
     * that some properties were added to an action type since the action was previously saved.
     * This function will be called for any action that was instantiated from this action type to ensure
     * that the settings are as expected. Therefore, this function helps to ensure backward compatibility
     * along the way.
     */
    resolveActionSettings: (action: DoppeDtoAction<PARTIAL_PROPS>) => PROPS;

    canRenderInPage?: DoppeActionTypeRenderingInPageSupport;

    isWidgetOpener: boolean;

    isLinkOpener?: Evaluable<(actionContext: IDoppeViewerActionContext<PROPS>) => boolean>;

    isListItemLinkOpener?: boolean;

    notSupportsRenderingPreviewItem?: boolean;

    supportsListLayout?: boolean;

    isModalOpener?: Evaluable<(actionContext: IDoppeViewerActionContext<PROPS>) => boolean>;

    hasNoGoalMeasuring?: boolean;

    hasNoLockInnerCTA?: boolean;

    skipReportingOnGoalAchievedOnActionButtonClick?: Evaluable<(actionContext: IDoppeViewerActionContext<PROPS>) => boolean>;

    withoutClientMetadata?: boolean;

    isPaymentAction?: boolean;
    isFormAction?: boolean;
    getSupportedAutomationTrigger?: () => DoppeAutomationTriggers;
    isPremiumAction?: (premiumFeaturesStatus: DoppePremiumFeaturesStatus | null) => boolean;

    /**
     * Action that renders media should provide this method to resolve such media from its settings.
     * This can be used for:
     * - Background importing of external images that are used by the action.
     * - Used media suggestion when replacing a media in one of the page settings.
     */
    resolveUsedMedia?: (actionSettings: PROPS) => WixMediaResource[];

    replaceExternalImages?: (actionSettings: PROPS, externalImagesUrlToImageResourceMap: Map<string, WixImageResource>) => PROPS;

    resolveUsedBusinessEntities?: (actionSettings: PROPS) => DoppeBusinessEntityReference[];

    resolveListItemsCount?: (actionSettings: PROPS) => number;

    lockIsNotSupported?: boolean;

    hasDisplaySettings?: boolean;

    resolveListItems?: (action: DoppeDtoAction<PROPS>) => DoppeDtoAction.ActionListItem<PROPS>[];

    isConnectedToExternalDataSource?: (actionSettings: PROPS) => boolean;

    resolveCouponData?: (actionSettings: PROPS) => DoppeDtoCouponCodeData;

    resolveCouponDataFromListItem?: (listItem: DoppeDtoAction.ActionListItem<PROPS>) => DoppeDtoCouponCodeData;

    resolveMainLink?: (actionSettings: PROPS) => string | null;

    resolveListSearchSettings?: (actionSettings: PROPS) => ListSearchSettings;

    resolveMainMedia?: (actionSettings: PROPS) => WixMediaResource | null;

    prepareForViewer?: (actionSettings: PROPS, options: DoppeActionTypePrepareForViewerOptions) => void;
}

export type DoppeActionTypePrepareForViewerOptions = {
    isLocked: boolean;
    isInnerCtaLocked: boolean;
    omitLockedActionsSettings: boolean;
}

export type DoppeActionType<PROPS extends {}> = DoppeActionTypeMetadata<PROPS> & DoppeActionTypeViewerMetadata<PROPS>;

export namespace DoppeActionType {
    export type Id = string;
}

export function doppeActionTypeSupportsRenderingAsStrip (actionType: DoppeActionTypeMetadata<any>) {
    const renderingInPageSupport = actionType.canRenderInPage ?? DoppeActionTypeRenderingInPageSupport.Disabled;

    switch (renderingInPageSupport) {
        case DoppeActionTypeRenderingInPageSupport.Disabled:
        case DoppeActionTypeRenderingInPageSupport.AsCardOnly: {
            return false;
        }
        case DoppeActionTypeRenderingInPageSupport.FullyEnabled: {
            return true;
        }
    }
}

export function doppeActionTypeSupportsInPageView(actionType: DoppeActionTypeMetadata<any>) {

    const renderingInPageSupport = actionType.canRenderInPage ?? DoppeActionTypeRenderingInPageSupport.Disabled;

    switch (renderingInPageSupport) {
        case DoppeActionTypeRenderingInPageSupport.AsCardOnly:
        case DoppeActionTypeRenderingInPageSupport.FullyEnabled: {
            return true;
        }
        case DoppeActionTypeRenderingInPageSupport.Disabled: {
            return false;
        }
    }
}



export function doppeActionTypeSupportsListRendersPreviewItem (actionType: DoppeActionTypeMetadata<any>) {
    return !actionType.notSupportsRenderingPreviewItem;
}

export function doppeActionTypeIsListActionType (actionType: DoppeActionTypeMetadata<any>) {
    return actionType.resolveListItems !== undefined;
}

export function doppeActionTypeIsSupportsLock (actionType: DoppeActionTypeMetadata<any>) {
    return actionType.lockIsNotSupported !== true;
}

export function doppeActionTypeHasDisplaySettings (actionType: DoppeActionTypeMetadata<any>) {
    return actionType.hasDisplaySettings !== false;
}


export function doppeActionTypeGetSupportedAutomationTrigger (actionType: DoppeActionTypeMetadata<any>) {
    return actionType.getSupportedAutomationTrigger?.() ?? null satisfies DoppeAutomationTriggers | null;
}


export function doppeActionTypeSupportsLockInnerCTA(actionType: DoppeActionTypeMetadata<any>) {
    return !actionType.hasNoLockInnerCTA;
}

export function doppeActionTypeSupportsWidgetOpening(actionType: DoppeActionTypeMetadata<any>) {
    return actionType.isWidgetOpener;
}

export function doppeActionTypeSupportsRenderingAsButton (actionType: DoppeActionTypeMetadata<any>) {
    if (doppeActionTypeSupportsInPageView(actionType)) {
        return doppeActionTypeSupportsWidgetOpening(actionType);
    } else {
        return true;
    }
}

export function doppeActionTypeSupportsOpeningWidgetAsPopup (actionType: DoppeActionTypeMetadata<any>) {
    return doppeActionTypeSupportsWidgetOpening(actionType) &&
        (doppeActionTypeSupportsInPageView(actionType) || actionType.isModalOpener);
}

export function doppeActionTypeSupportsOpeningWidgetAsLink (actionType: DoppeActionType<any>) {
    return actionType.isLinkOpener ?? false;
}

export function doppeActionTypeSupportsOpeningListItemWidgetAsLink (actionType: DoppeActionType<any>) {
    return actionType.isListItemLinkOpener ?? false;
}

export function doppeActionTypeCreateSettingsResolvingFunction<SETTINGS extends {}>(settings: SETTINGS) {
    return (action: DoppeDtoAction<any>) => {

        const actionSettings = action.settings;

        return objectMapValues(settings, (value, key) => {
            return actionSettings[key] ?? value;
        }) as SETTINGS;
    }
}

export function doppeActionTypeResolveSettingsFromAction<SETTINGS extends {}>(
    actionType: DoppeActionType<SETTINGS>,
    action: DoppeDtoAction<SETTINGS>
) {
    return actionType.resolveActionSettings(action)
}


/**
 * @deprecated You should not create a DoppeActionType object, but to register the metadata and the viewer metadata in
 * DoppeActionTypesStore and in DoppeActionTypesMetadataStore
 */
export function doppeActionTypeDefineNew<PROPS extends {} = {}>(
    actionTypeMetadata: DoppeActionTypeMetadata<PROPS>,
    actionTypeViewerMetadata: DoppeActionTypeViewerMetadata<PROPS>
) : DoppeActionType<PROPS> {
    return {
        ...actionTypeMetadata,
        ...actionTypeViewerMetadata
    }
}

export function doppeActionTypeDefineMetadata<PROPS extends {} = {}, PARTIAL_AND_DEPRECATED_PROPS extends {} = Partial<PROPS>>(actionType: DoppeActionTypeMetadata<PROPS, PARTIAL_AND_DEPRECATED_PROPS>) {
    return actionType;
}

export function doppeActionTypeDefineViewerMetadata<PROPS extends {} = {}, VIEWER_COMP = unknown>(
    _actionType: DoppeActionTypeMetadata<PROPS>,
    viewerMetadata: DoppeActionTypeViewerMetadata<PROPS, VIEWER_COMP>
) {
    return viewerMetadata;
}

export function doppeActionTypeInstantiate<ACTION_TYPE extends DoppeActionType<any>>(
    actionType: ACTION_TYPE,
    actionData: DoppeActionType.InstantiationData<ACTION_TYPE>
): DoppeDtoAction<DoppeActionType.InferProps<ACTION_TYPE>> {

    const action = doppeDtoActionExtendWithDefaultProps({
        id: actionData.id ?? uuidv4(),
        actionTypeId: actionType.id,
        name: actionData.name,
        callToActionDescription: actionData.callToActionDescription ?? doppeHideableValueCreateHidden(''),
        enabled: actionData.enabled ?? true,
        icon: actionData.icon,
        widgetOpeningMode: actionData.widgetOpeningMode,
        settings: {
            ...actionData.settings
        },
        viewType: actionData.viewType,
        showActionHeader: actionData.showActionHeader ?? true,
        tag: actionData.tag,
        searchSettings: {
            ...doppeDtoActionGetDefaultSearchSettingsProps(),
            ...actionData.searchSettings
        },
        bannerMedia: actionData.bannerMedia ?? null,
        lockSettings:  {
            ...doppeDtoActionGetDefaultLockSettingsProps(),
            ...actionData.lockSettings
        },
    }) satisfies DoppeDtoAction;

    return {
        ...action,
        settings: doppeActionTypeResolveSettingsFromAction(actionType, action)
    }
}

export function doppeActionTypeInstantiateLockAction<ACTION_TYPE extends DoppeActionType<any>>(
    actionType: ACTION_TYPE,
    actionData: DoppeActionType.InstantiationData<ACTION_TYPE>
): DoppeDtoAction<DoppeActionType.InferProps<ACTION_TYPE>> {

    return doppeActionTypeInstantiate(actionType, {
        ...actionData,
    });
}

export interface IDoppeActionFactory {

    createAction<ACTION_TYPE extends DoppeActionType<any>>(
        actionType: ACTION_TYPE,
        actionData: {
            name: DoppeDtoAction['name'];
            enabled?: DoppeDtoAction['enabled'];
            viewType?: DoppeDtoAction['viewType'];
            showActionHeader?: DoppeDtoAction['showActionHeader'];
            settings: DoppeActionType.InferProps<ACTION_TYPE>
        }
    ): DoppeDtoAction<DoppeActionType.InferProps<ACTION_TYPE>>;
}

export const DoppeActionsSourceType = {
    Page: 'page',
    Search: 'search',
} as const;

export type DoppeActionsSourceType = Values<typeof DoppeActionsSourceType>;


export const DoppeActionGoalType = {
    Share: 'share',
    Click: 'click',
    WhatsappClick: 'whatsappClick',
    CallButtonClick: 'callButtonClick',
    EmailButtonClick: 'emailButtonClick',
    NavigateButtonClick: 'navigateButtonClick',
    FacebookMessageButtonClick: 'facebookMessageButtonClick',
    SocialIconClick: 'socialIconClick',
    SearchClick: 'searchClick',
    CouponCopied: 'couponCopied',
    FormSubmission: 'formSubmission',
    Subscribe: 'subscribe',
    Payment: 'payment',
    Donation: 'donate',
    Views: 'view',
    EmbedWidgetViewed: 'embedWidgetViewed',
    SaveContact: 'saveContact',
    PlayVideo: 'playVideo',
} as const;

export type DoppeActionGoalType = Values<typeof DoppeActionGoalType>;

export const WidgetRenderingContextType = {
    Card: 'Card',
    Strip: 'Strip',
    Modal: 'Modal',
} as const;

export type WidgetRenderingContextType = Values<typeof WidgetRenderingContextType>;

export const WidgetModalRenderingContextType = enumReduce(WidgetRenderingContextType, [
    WidgetRenderingContextType.Modal
]);

export type WidgetModalRenderingContextType = Values<typeof WidgetModalRenderingContextType>;

export interface WidgetLockController {
    unlock: (unlockedMetaData?: any) => Promise<boolean>;
    lockedActionId: string;
}


