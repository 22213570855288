import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeActionTypeOutfitViewerLang} from './doppe-action-type-outfit-viewer.lang';
import {DoppeActionTypeProduct} from '../doppe-action-type-product/doppe-action-type-product';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    iterableMapToArray,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionLayout,
    doppeExternalProductProviderResolveBusinessEntityReference,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionListItemGetDefaultListItemSettings,
    DoppeListActionSettings
} from '../../client-server-common';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {doppeActionCtaButtonResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';

export namespace DoppeActionTypeOutfit {

    export interface ProductSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeProduct.ActionSettings {
        productTitle: string;
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings<ProductSettings>,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithItemClickBehaviour
    {
        outfitImage: WixMediaResource | null;
        outfitTitle: DoppeHideableValue<string>;
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowCoupon: boolean;
        previewProductButtonText: string;
    }

    export interface DeprecatedFields {
        products?: ProductSettings[];
    }
}

export const doppeActionTypeOutfit = doppeActionTypeDefineMetadata<DoppeActionTypeOutfit.ActionSettings>({
    id: 'io.bymo.action/outfit',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ...restSettings
        } = action.settings;

        return {
            outfitImage: null,
            outfitTitle: DoppeActionTypeOutfitViewerLang.defaultTexts.outfitTitle,

            itemShowPrice: true,
            itemShowDate: false,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewProductButtonText: '',
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Grid,
                listDataItems: listDataItems.map(product => {

                    const {
                        productButtonText,
                    } = product;

                    return {
                        productProvider: null,
                        productProviderId: null,
                        ribbon: doppeHideableValueCreateHidden('SALE'),
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: productButtonText,
                            showButton: true
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettings(product.id),
                        ...product as PartiallyOptional<DoppeActionTypeOutfit.ProductSettings, 'productProvider' | 'productProviderId' | 'ribbon' | 'ctaButton'>
                    }
                }) ?? [],
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...restSettings
        }
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.listDataItems.forEach(itemSettings => {
                itemSettings.productPageLink = ''
                doppeActionCtaButtonResetSettingsForLock(itemSettings);
            });

            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    resolveUsedMedia: actionSettings => {
        const images = arrayFlatten(actionSettings.listDataItems.map(product => {
            return product.productImage ? asArray(product.productImage) : []
        }));

        return arrayRemoveNullValues([actionSettings.outfitImage, ...images]);
    },
    resolveMainMedia: actionSettings => {
        const images = arrayFlatten(actionSettings.listDataItems.map(product => {
            return product.productImage ? asArray(product.productImage) : []
        }));

        return actionSettings.outfitImage ?? images[0] ?? null;
    },
    resolveUsedBusinessEntities: actionSettings => {
        return iterableMapToArray(actionSettings.listDataItems, (product, skip) => {
            return product.productProvider !== null && product.productProviderId !== null ?
                doppeExternalProductProviderResolveBusinessEntityReference(
                    product.productProvider,
                    product.productProviderId
                ) : skip
        });
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.listDataItems.length;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.outfitImage) {
                actionSettings.outfitImage =
                    wixMediaResourceReplaceExternalImage(actionSettings.outfitImage, externalImagesUrlToImageResourceMap)
            }

            for (const item of actionSettings.listDataItems) {
                if (item.productImage) {
                    const images = asArray(item.productImage);
                    item.productImage = images.map(image => {
                        return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                    });
                }
            }
        })
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.productTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.description,
                image: item.productImage ? asArray(item.productImage)[0] : null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.productPageLink,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },

});