import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetVisibleValue
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {arrayRemoveNullValues, autoValueCreate, immerProduce} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL,
    DoppeActionCTAType,
    DoppeActionCTATypeLinkAutoSource
} from '../../client-server-common/types/doppe-action-cta-button';

export namespace DoppeActionTypeShare {
    export interface ActionSettings extends DoppeActionCtaButtonSettings {
        shareLink: string;
        shareTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        shareButtonText: string;
        shareImage: WixMediaResource | null;
    }
}

export const doppeActionTypeShare = doppeActionTypeDefineMetadata<DoppeActionTypeShare.ActionSettings>({
    id: 'io.bymo.action/share',
    resolveActionSettings: action => {

        const {
            shareButtonText = '',
            shareTitle = doppeHideableValueCreateHidden(''),
            ...restSettings
        } = action.settings;

        return {
            shareLink: '',
            shareTitle: shareTitle,
            description: '',
            shareButtonText: '',
            shareImage: null,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: shareButtonText,
                showButton: true,
                settings: {
                    type: DoppeActionCTAType.Share,
                    title:  doppeHideableValueGetVisibleValue(shareTitle, ''),
                    description: '',
                    url: autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL),
                }
            }),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([actionSettings.shareImage]);
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.shareImage;
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings, actionSettings.shareLink) ?? null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.shareImage) {
                actionSettings.shareImage =
                    wixMediaResourceReplaceExternalImage(actionSettings.shareImage, externalImagesUrlToImageResourceMap)
            }
        })
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.shareLink = '';
            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },

});