import {
    AutoValue,
    autoValueCreate,
    autoValueIsAutoValue,
    evaluateFunction,
    stringTrimPrefix,
    validateIsEmail,
    validateIsUrl,
    Values
} from '@wix/devzai-utils-common';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetVisibleValue
} from './doppe-hideable-value';
import {DoppeActionGoalType} from '../doppe-action-types/doppe-action-type';
import {WixImageResource} from '@wix/devzai-common-wix';
import {Button} from '@wix/devzai-utils-react';
import {DoppeActionCtaButtonSettings} from './doppe-action-cta-button-settings';


export const DoppeActionCTAType = {
    Link: 'Link',
    Whatsapp: 'Whatsapp',
    Call: 'Call',
    Navigate: 'Navigate',
    Email: 'Email',
    FacebookMessage: 'FacebookMessage',
    SaveContact: 'SaveContact',
    Share: 'Share'
} as const;

export type DoppeActionCTAType = Values<typeof DoppeActionCTAType>;

export const DoppeActionCTATypeLinkAutoSource = {
    SameAsSourceURL: 'SameAsSourceURL',
} as const;

export type DoppeActionCTATypeLinkAutoSource = Values<typeof DoppeActionCTATypeLinkAutoSource>;

export type DoppeActionCTATypeLinkSource = AutoValue<DoppeActionCTATypeLinkAutoSource> | string;

export type DoppeActionCTAButton = {
    settings: DoppeActionSettingsCTAButtonSettings;
    text: DoppeHideableValue<string>;
    showButton: boolean;
}

export type DoppeActionSettingsCTAButtonSettings =
    | DoppeActionSettingsCTAButtonSettings.Link
    | DoppeActionSettingsCTAButtonSettings.Whatsapp
    | DoppeActionSettingsCTAButtonSettings.Call
    | DoppeActionSettingsCTAButtonSettings.Navigate
    | DoppeActionSettingsCTAButtonSettings.Email
    | DoppeActionSettingsCTAButtonSettings.FacebookMessage
    | DoppeActionSettingsCTAButtonSettings.SaveContact
    | DoppeActionSettingsCTAButtonSettings.Share;

export namespace DoppeActionSettingsCTAButtonSettings {

    type BaseType<T extends DoppeActionCTAType> = {
        type: T;
    }

    export type Link = BaseType<typeof DoppeActionCTAType.Link> & {
        url: DoppeActionCTATypeLinkSource;
    };

    export type Whatsapp = BaseType<typeof DoppeActionCTAType.Whatsapp> & {
        phone: string;
        customMessage: DoppeHideableValue<string>;
    };

    export type Call = BaseType<typeof DoppeActionCTAType.Call> & {
        phone: string;
    };

    export type Email = BaseType<typeof DoppeActionCTAType.Email> & {
        email: string;
        customSubject: DoppeHideableValue<string>;
        customMessage: DoppeHideableValue<string>;
    };

    export type Navigate = BaseType<typeof DoppeActionCTAType.Navigate> & {
        address: string;
    };

    export type FacebookMessage = BaseType<typeof DoppeActionCTAType.FacebookMessage> & {
        userName: string;
        customMessage: DoppeHideableValue<string>
    };

    export type SaveContact = BaseType<typeof DoppeActionCTAType.SaveContact> & {
        notes: string;
        saveContactProfileImage: DoppeHideableValue<WixImageResource | null>
    }

    export type Share = BaseType<typeof DoppeActionCTAType.Share> & {
        url: DoppeActionCTATypeLinkSource;
        title: string;
        description: string;
    }

}


export function doppeActionCTAButtonIsVisible(ctaButton: DoppeActionCTAButton) {
    return ctaButton.showButton;
}

export function doppeActionCTAButtonCreateLinkSameAsSource(options: {
    text: string,
    hidden?: boolean
}) {

    const {
        text,
        hidden = false
    } = options;

    return {
        settings: {
            type: DoppeActionCTAType.Link,
            url: autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL),
        },
        text: text,
        showButton: !hidden
    }
}

export function doppeActionCtaButtonTypeGetLinkSettings(ctaButton: DoppeActionCTAButton, options?: {
    customURL?: string,
}) {

    const {
        type,
    } = ctaButton.settings;

    const {
        customURL,
    } = options ?? {}

    const xxx = evaluateFunction(() => {
        switch (type) {
            default: {

                const isURLType = doppeActionCtaButtonTypeIsURL(ctaButton.settings);

                if (isURLType) {
                    return {
                        href: doppeActionCtaButtonTypeResolveURL(ctaButton.settings, customURL),
                        target: '_blank',
                        rel: 'noopener',
                        tagName: 'a',
                    } as {
                        href: string;
                        target: string;
                        rel: string;
                        tagName: Button.Props['tagName'];
                    }
                } else {
                    return undefined;
                }

            }
        }
    })

    return xxx;
}

export function doppeActionCtaButtonTypeGetGoalType(type: DoppeActionCTAType) {

    switch (type) {
        case DoppeActionCTAType.Whatsapp: {
            return DoppeActionGoalType.WhatsappClick;
        }
        case DoppeActionCTAType.Call: {
            return DoppeActionGoalType.CallButtonClick;
        }
        case DoppeActionCTAType.Link: {
            return DoppeActionGoalType.Click;
        }
        case DoppeActionCTAType.Email: {
            return DoppeActionGoalType.EmailButtonClick;
        }
        case DoppeActionCTAType.Navigate: {
            return DoppeActionGoalType.NavigateButtonClick;
        }
        case DoppeActionCTAType.FacebookMessage: {
            return DoppeActionGoalType.FacebookMessageButtonClick;
        }
        case DoppeActionCTAType.SaveContact: {
            return DoppeActionGoalType.SaveContact;
        }
        case DoppeActionCTAType.Share: {
            return DoppeActionGoalType.Share;
        }

    }
}

export function doppeActionCtaButtonResetSettingsForLock(actionSettings: DoppeActionCtaButtonSettings) {
    const isSameSourceURl = (
            actionSettings.ctaButton.settings.type === DoppeActionCTAType.Link ||
            actionSettings.ctaButton.settings.type === DoppeActionCTAType.Share
        )
        && autoValueIsAutoValue(actionSettings.ctaButton.settings.url
        );
    actionSettings.ctaButton.settings = doppeActionCtaButtonTypeGetDefaultSettings(actionSettings.ctaButton.settings.type, isSameSourceURl);
}

export function doppeActionCtaButtonTypeGetDefaultSettings(type: DoppeActionCTAType, hasSourceURL: boolean) {

    switch (type) {
        case DoppeActionCTAType.Whatsapp: {
            return {
                type: DoppeActionCTAType.Whatsapp,
                phone: '',
                customMessage: '',
            };
        }
        case DoppeActionCTAType.Call: {
            return {
                type: DoppeActionCTAType.Call,
                phone: '',
            };
        }
        case DoppeActionCTAType.Share:
            return {
                type: DoppeActionCTAType.Share,
                url: hasSourceURL ? autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL) : '',
                title: '',
                description: '',
            };
        case DoppeActionCTAType.Link:
            return {
                type: DoppeActionCTAType.Link,
                url: hasSourceURL ? autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL) : '',
            };
        case DoppeActionCTAType.Email: {
            return {
                type: DoppeActionCTAType.Email,
                email: '',
                customMessage: '',
                customSubject: ''
            };
        }
        case DoppeActionCTAType.Navigate: {
            return {
                type: DoppeActionCTAType.Navigate,
                address: '',
            };
        }
        case DoppeActionCTAType.FacebookMessage: {
            return {
                type: DoppeActionCTAType.FacebookMessage,
                userName: '',
                customMessage: '',
            };
        }
        case DoppeActionCTAType.SaveContact: {
            return {
                type: DoppeActionCTAType.SaveContact,
                notes: '',
                saveContactProfileImage: doppeHideableValueCreateHidden(null),
            };
        }
    }
}

export function doppeActionCtaButtonTypeIsURL(ctaSettings: DoppeActionSettingsCTAButtonSettings) {
    switch (ctaSettings.type) {
        case DoppeActionCTAType.Call:
        case DoppeActionCTAType.Email:
        case DoppeActionCTAType.Link:
        case DoppeActionCTAType.Whatsapp:
        case DoppeActionCTAType.Navigate:
        case DoppeActionCTAType.FacebookMessage: {
            return true;
        }
        case DoppeActionCTAType.Share:
        case DoppeActionCTAType.SaveContact: {
            return false;
        }
    }
}

export function doppeActionCtaButtonTypeResolveURL(ctaSettings: DoppeActionSettingsCTAButtonSettings, sourceURL?: string) {

    switch (ctaSettings.type) {
        case DoppeActionCTAType.Call: {
            return `tel:${ctaSettings.phone}`;
        }
        case DoppeActionCTAType.Email: {

            if (!validateIsEmail(ctaSettings.email)) {
                return undefined;
            }

            return `mailto:${ctaSettings.email}?subject=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customSubject, ''))}&body=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customMessage, ''))}`
        }
        case DoppeActionCTAType.Share:
        case DoppeActionCTAType.Link: {

            const url = autoValueIsAutoValue(ctaSettings.url) ? sourceURL ?? '' : ctaSettings.url;

            if (validateIsUrl(url, {
                protocols: ['http', 'https'],
                require_protocol: true
            })) {
                return url;
            } else {
                return '';
            }

        }
        case DoppeActionCTAType.Whatsapp: {
            return `https://api.whatsapp.com/send?phone=${stringTrimPrefix(ctaSettings.phone, '+')}&text=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customMessage, ''))}`;
        }
        case DoppeActionCTAType.Navigate: {
            if (!ctaSettings.address) {
                return undefined;
            }

            return `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(ctaSettings.address)}`
        }
        case DoppeActionCTAType.FacebookMessage: {
            return ctaSettings.userName !== undefined ?
                `https://www.m.me/${ctaSettings.userName}?text=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customMessage, ''))}` :
                undefined;
        }
        case DoppeActionCTAType.SaveContact: {
            return undefined;
        }
    }
}

